@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Patrick Hand SC", cursive;
}

.patrick-hand-sc-regular {
  font-family: "Patrick Hand SC", cursive;
}

@keyframes fadeInFromAbove {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-from-above {
  animation: fadeInFromAbove 0.5s ease-out forwards;
}
